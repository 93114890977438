import React from 'react'
import Link from 'next/link';
import Image from 'next/image';

const FrontPage = () => {
  return (
    <div className="Front">
      <header>
        <Link href="/"><button>Home</button></Link>
        &nbsp;
        <Link href="/ContactForm"><button>Contact</button></Link>
        &nbsp;
        <Link href="/LearnMore"><button>Learn More</button></Link>
        &nbsp;
        <Link href="/LoginForm"><button>Login</button></Link>
        &nbsp;
        <Link className="signup-button" href="/RegistrationForm"><button>Sign-Up</button></Link>
      </header>
      <span className="hero hero-title">
        <h1>
          Unearth Root Causes and Discover Genuine Solutions with Five Why Finder!
        </h1>

        <div className="robot-container-front-page">
          <Image className="topRobot" src="/new-robot-transparent-background-animated.gif" width="420" height="420" alt="robot" priority="true"/>
          <div className="left speech">          
          Hi, I&apos;m WhyBot, your virtual problem-solving assistant. Using advanced machine learning, I&apos;ll help solve your problem using five why analysis. It&apos;s easy, just tell me your problem and I&apos;ll let you know the root cause. I&apos;ll even give you some ideas on how to prevent the problem from happening again.
          &nbsp;
          <br/><br/>
          <Link href="/RegistrationForm" style={{color: '#fff', fontWeight: 'bold'}}>Register now</Link> and get 50 credits free. Let&apos;s solve problems together.
          &nbsp;<i className="fa fa-coins" aria-hidden="true"></i>
          </div>
        </div>
      </span>

      <section className="features">
        <h2 className="section-title">Features Tailored for Your Success</h2>
        <div className="features-wrapper">
          <div className="feature-item">
            <h3>Intuitive Interface</h3>
            <Image src="/intuitive-interface-3.png" alt="intuitive-interface" width="200" height="200"/>
            <p>Navigate through complex issues with ease using our user-friendly design.</p>
          </div>
          <div className="feature-item">
            <h3>Smart Analysis</h3>
            <Image src="/smart-analysis.png" alt="smart-analysis" width="200" height="200"/>
            <p>Leverage the power of machine learning to reach the root of your problems in a fraction of the time.</p>
          </div>
          <div className="feature-item">
            <h3>Real-time Solutions</h3>
            <Image src="/real-time-solutions-2.png" alt="smart-analysis" width="200" height="200"/>
            <p>Don&apos;t just identify problems - our technology helps you uncover practical solutions instantly.</p>
          </div>
        </div>
      </section>

      <section className='journey'>
        <h2>Your Journey to Better Outcomes Begins Here</h2>
        <div className="journey-wrapper">
          <div className="journey-item">
            <h3>Security & Privacy</h3>
            <Image src="/security.jpg" alt="Improved Decision-Making" width="200" height="200"/>
            <p>Rest easy knowing your data is protected by our cutting-edge security technology.</p>
          </div>
          <div className="journey-item">
            <h3>Faster Incident Response</h3>
            <Image src="/faster-incidence-response-3.png" alt="faster-incidence-response" width="200" height="200"/>
            <p>Dramatically reduce your response time by knowing exactly what to fix.</p>
          </div>
          <div className="journey-item">
            <h3>Prevent Future Incidents</h3>
            <Image src="/prevent-future-incidents.png" alt="prevent-future-incidents" width="200" height="200"/>
            <p>Use your analysis to prevent the same problems from recurring in the future.</p>
          </div>
        </div>
      </section>


      <section className='cta'>
        <h2 className="hero-title-2">Start Your Exploration of Root-Cause Analysis Today</h2>

        <p style={{fontWeight: 'bold', marginTop: '55px'}}>Join the many others who are already uncovering their &apos;whys&apos; and improving their problem-solving process.</p>
      </section>        

      <section className="cta">
        <Link className="signup-button" href="/RegistrationForm"><button>Start Finding Why</button></Link>
      </section>

      <footer>
        <p>&copy; {new Date().getFullYear()} FiveWhyFinder.com. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default FrontPage;
